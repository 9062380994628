// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu{
    .menu-text {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        div{
            color: white;
            cursor: pointer;
            font-size: x-large;
        }
    }
    .current-page{
        -webkit-text-decoration: underline white;
                text-decoration: underline white;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,6BAA6B;QAC7B;YACI,YAAY;YACZ,eAAe;YACf,kBAAkB;QACtB;IACJ;IACA;QACI,wCAAgC;gBAAhC,gCAAgC;IACpC;AACJ","sourcesContent":[".menu{\n    .menu-text {\n        display: flex;\n        align-items: center;\n        justify-content: space-evenly;\n        div{\n            color: white;\n            cursor: pointer;\n            font-size: x-large;\n        }\n    }\n    .current-page{\n        text-decoration: underline white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
