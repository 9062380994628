import React from "react";
import {
    Projects,
    Volumes
} from "../../constants/Pages";
import {default as VolumesComponent} from "./Volumes";
import {default as ProjectsComponent} from "./Projects";

type HomepageProps = {
    page: string
}

export const Homepage: React.FC<HomepageProps> = ({page}) => {
    switch (page) {
        case Volumes:
            return (
                <VolumesComponent/>
            )
        case Projects:
            return (
                <ProjectsComponent/>
            )
        default:
            return (
                <VolumesComponent/>
            )
    }
}


export default Homepage;