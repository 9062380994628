import './App.css';
import React, {useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Homepage from "./components/homepage/Homepage";
import Menu from "./components/Menu/Menu";
import {Volumes} from "./constants/Pages";


function App() {
    const [page, setPage] = useState(Volumes)

    return (
          <BrowserRouter>
              <Menu page={page} setPage={setPage}/>
              <Routes>
                  <Route path="/" element={<Homepage page={page}/>}/>
              </Routes>

          </BrowserRouter>
    );
}

export default App;
