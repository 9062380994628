import React from "react";
import {Projects, Volumes} from "../../constants/Pages";
import "./Menu.css"


type MenuProps = {
    page: string,
    setPage: React.Dispatch<string>
}

const Menu: React.FC<MenuProps> = ({page, setPage}) => {

    return (
        <div className={"menu"}>
            <h1>WEB-ALMANAC</h1>
            <div className={"menu-text"}>
                <div onClick={() => {setPage(Volumes)}} className={page === Volumes ? "current-page" : ""}>{Volumes}</div>
                <div onClick={() => {setPage(Projects)}} className={page === Projects ? "current-page" : ""}>{Projects}</div>
            </div>
        </div>
    )
}

export default Menu