import React from "react";
import "./Homepage.css"
import {Link} from "react-router-dom";
import {WABOT, WATG} from "../../constants/Urls";

const Projects: React.FC = () => {
    return (
        <div className={"projects"}>
            <div className={"container text-core"}>
                    <Link className={"link-vol"} to={WABOT}>
                        <h6>web-almanac bot</h6>
                    </Link>
                    <Link className={"link-vol"} to={WATG}>
                        <h6>web-almanac tg</h6>
                    </Link>
            </div>
        </div>
    )
}

export default Projects