import React from "react";
import {Link} from "react-router-dom";
import {WAVOL1, WAVOL2} from "../../constants/Urls";

const Volumes: React.FC = () =>{
    const stars = Array.from({ length: 1000 }, (_, index) => ({
        id: index,
        x: Math.random() * 100,
        y: Math.random() * 100,
        wh: Math.random() + 0.2
    }));
    return (
        <div className={"volumes"}>
            <>
                <Link className={"link-vol"} style={{padding: "1rem"}} to={WAVOL1}>vol 1 -&gt;</Link>
                <div className={"volumes-container"}>

                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe src={WAVOL1} sandbox="allow-scripts allow-popups">
                </iframe>
                </div>
            </>
            <>
                <Link className={"link-vol"} to={WAVOL2} style={{padding: "1rem"}}>vol 2 -&gt;</Link>
                <div className={"volumes-container"}>
                    <Link to={WAVOL2}>

                    <div id="star-sky" className="star-sky">
                        {stars.map(star => (
                            <div
                                key={star.id}
                                className="star"
                                style={{left: `${star.x}%`, top: `${star.y}%`, width: star.wh, height: star.wh}}
                            />
                        ))}
                    </div>
                    </Link>

                </div>

            </>
        </div>
    )
}

export default Volumes