// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://db.onlinewebfonts.com/c/4b5ae315f5df148f0ab479a7cedc9832?family=ISOCPEUR);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "ISOCPEUR";
  src: url("https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.eot");
  src: url("https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.svg#ISOCPEUR")format("svg");
}
html{
  font-family: "ISOCPEUR", serif;
}

body {
  background-color: black;
}

.center-content{
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-vol, .text {
  font-size: xx-large;
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.text-core {
  color: white;
  font-size: x-large;

}

h1 {
  display: flex;
  justify-content: center;
  color: white;
  font-size: xxx-large;
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,uBAAuB;EACvB,gFAAgF;EAChF;;;;mGAIiG;AACnG;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,kBAAkB;;AAEpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":["@import url(https://db.onlinewebfonts.com/c/4b5ae315f5df148f0ab479a7cedc9832?family=ISOCPEUR);\n@font-face {\n  font-family: \"ISOCPEUR\";\n  src: url(\"https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.eot\");\n  src: url(\"https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.eot?#iefix\")format(\"embedded-opentype\"),\n  url(\"https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.woff2\")format(\"woff2\"),\n  url(\"https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.woff\")format(\"woff\"),\n  url(\"https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.ttf\")format(\"truetype\"),\n  url(\"https://db.onlinewebfonts.com/t/4b5ae315f5df148f0ab479a7cedc9832.svg#ISOCPEUR\")format(\"svg\");\n}\nhtml{\n  font-family: \"ISOCPEUR\", serif;\n}\n\nbody {\n  background-color: black;\n}\n\n.center-content{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.link-vol, .text {\n  font-size: xx-large;\n  display: flex;\n  justify-content: center;\n  color: white;\n  text-decoration: none;\n}\n\n.text-core {\n  color: white;\n  font-size: x-large;\n\n}\n\nh1 {\n  display: flex;\n  justify-content: center;\n  color: white;\n  font-size: xxx-large;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
