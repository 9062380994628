// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

.projects {
    .container {
        margin-top: 10vh;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5vw;
        a {
            border: aqua dotted;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.volumes {
    padding: 1rem 6rem;
    .volumes-container {
        border: 1rem solid #ccc;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .star-sky {
        position: relative;
        width: 100%;
        height: 40vh;
        overflow: hidden;
        background: black;
        border-radius: 50% / 20%;
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5);
        transition: transform 0.2s;
    }

    .star {
        position: absolute;
        background: white;
        border-radius: 50%;
    }

    iframe {
        width: 100%;
        height: 40vh;
        border: none;
    }
}

/* Mobile */
@media (max-width: 700px) {
    .volumes, .projects {
        padding: 1rem 3rem;
    }
}

/* Desktop */
@media (min-width: 700px) {
    .volumes, .projects {
        padding: 1rem 6rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/homepage/Homepage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,gBAAgB;QAChB,aAAa;QACb,qCAAqC;QACrC,aAAa;QACb;YACI,mBAAmB;YACnB,YAAY;YACZ,aAAa;YACb,mBAAmB;YACnB,uBAAuB;QAC3B;IACJ;AACJ;;AAEA;IACI,kBAAkB;IAClB;QACI,uBAAuB;QACvB,WAAW;QACX,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,WAAW;QACX,YAAY;QACZ,gBAAgB;QAChB,iBAAiB;QACjB,wBAAwB;QACxB,0CAA0C;QAC1C,0BAA0B;IAC9B;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,YAAY;IAChB;AACJ;;AAEA,WAAW;AACX;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA,YAAY;AACZ;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\n.projects {\n    .container {\n        margin-top: 10vh;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        grid-gap: 5vw;\n        a {\n            border: aqua dotted;\n            height: 5rem;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n    }\n}\n\n.volumes {\n    padding: 1rem 6rem;\n    .volumes-container {\n        border: 1rem solid #ccc;\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        margin-bottom: 2rem;\n    }\n    .star-sky {\n        position: relative;\n        width: 100%;\n        height: 40vh;\n        overflow: hidden;\n        background: black;\n        border-radius: 50% / 20%;\n        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5);\n        transition: transform 0.2s;\n    }\n\n    .star {\n        position: absolute;\n        background: white;\n        border-radius: 50%;\n    }\n\n    iframe {\n        width: 100%;\n        height: 40vh;\n        border: none;\n    }\n}\n\n/* Mobile */\n@media (max-width: 700px) {\n    .volumes, .projects {\n        padding: 1rem 3rem;\n    }\n}\n\n/* Desktop */\n@media (min-width: 700px) {\n    .volumes, .projects {\n        padding: 1rem 6rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
